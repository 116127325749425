export function AboutSection() {
    return (
        <section id="about-section">
            <div className="section-title">
                About Us
            </div>
            <div className="section-body container">
                <p>
                    A Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  standard dummy text ever since the 1500s, when an unknown
                </p>

                <p>
                    printing and typesetting industry. Lorem Ipsum has been the industry's  standard dummy text ever since the 1500s, when an unknown
                </p>

                <div className="btn-container">
                    <div className="btn btn-outline">Learn More</div>
                </div>
            </div>
        </section>
    )
}